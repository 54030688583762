import React, { useContext, useEffect, useState } from "react";
import { fetchToken } from "../../firebase.js";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Title from "../../components/Title";
import { Button, InputAdornment, TextareaAutosize } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import { Can } from "../../components/Can";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainHeader from "../../components/MainHeader/index.js";
import { useSettingsContext } from "../../context/Setting/SettingsContext.js";

const useStyles = makeStyles((theme) => ({
  // root: {
  // 	display: "flex",
  // 	alignItems: "center",
  // 	padding: theme.spacing(8, 8, 3),
  // },

  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [notifications, setNotifications] = useState("disabled");
  const [showToken, setShowToken] = useState(false);

  const [keywordsIgnore, setKeywordsIgnore] = useState("");

  const { settings } = useSettingsContext();

  useEffect(() => {
    const fetchDeviceToken = async () => {
      try {
        await fetchToken(
          () => {},
          async (token) => {
            await api
              .get("/subscribe", {
                params: {
                  token,
                  userId: user.id,
                },
              })
              .then(() => {
                setNotifications("enabled");
                // set token to local storage
                localStorage.setItem("fcmToken", token);
              });
          }
        );
      } catch (err) {
        if (err) {
          setNotifications("disabled");
        }
      }
    };

    fetchDeviceToken();
  }, [user.id]);

  const handleChangeSettingNotification = async (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "enabled") {
      await fetchToken(
        () => {},
        async (token) => {
          await api.post("/subscribe", {
            token,
            userId: user.id,
          });

          setNotifications("enabled");

          localStorage.setItem("fcmToken", token);

          toast.success(i18n.t("settings.success"));
        },
        (err) => {
          toastError(err);
        }
      );
    } else {
      try {
        await fetchToken(
          () => {},
          async (token) => {
            await api.post("/unsubscribe", {
              userId: user.id,
              token,
            });
          }
        );

        setNotifications("disabled");

        localStorage.removeItem("fcmToken");

        toast.success(i18n.t("settings.success"));
      } catch (err) {
        toastError(err);
      }
    }
  };

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeSettingHyperMask = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    const relatedSettingKey =
      settingKey === "disableHyperlink" ? "maskLink" : "disableHyperlink";
    const relatedSelectedValue =
      selectedValue === "enabled" ? "disabled" : "enabled";

    try {
      await api.put(`/settings/${settingKey}`, { value: selectedValue });
      await api.put(`/settings/${relatedSettingKey}`, {
        value: relatedSelectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = (key) => {
    const { value } = settings && settings.length > 0 && settings.find((s) => s.key === key);
    return value;
  };

  const getBackEndVersion = () => {
    return localStorage.getItem("backendVersion") || "0.0.0";
  };

  const getFrontEndVersion = () => {
    return localStorage.getItem("frontendVersion") || "0.0.0";
  };

  const handleClickShowToken = () => {
    setShowToken(!showToken);
  };

  const handleMouseDownToken = (event) => {
    event.preventDefault();
  };

  const handleKeywordsIgnoreChange = (e) => {
    setKeywordsIgnore(e.target.value);
  };

  const handleKeywordsIgnoreClick = async () => {
    try {
      await api.put(`/settings/keywordsIgnore`, {
        value: keywordsIgnore,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <MainHeader>
          <Title>{i18n.t("settings.title")}</Title>
        </MainHeader>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item lg container spacing={2}>
              <Can
                role={user.profile}
                perform="settings:edit"
                yes={() => (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body1">
                      {i18n.t("settings.settings.userCreation.name")}
                    </Typography>
                    <Select
                      margin="dense"
                      variant="outlined"
                      native
                      id="userCreation-setting"
                      name="userCreation"
                      value={
                        settings &&
                        settings.length > 0 &&
                        getSettingValue("userCreation")
                      }
                      className={classes.settingOption}
                      onChange={handleChangeSetting}
                    >
                      <option value="enabled">
                        {i18n.t(
                          "settings.settings.userCreation.options.enabled"
                        )}
                      </option>
                      <option value="disabled">
                        {i18n.t(
                          "settings.settings.userCreation.options.disabled"
                        )}
                      </option>
                    </Select>
                  </Grid>
                )}
              />
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1">
                  {i18n.t("settings.settings.notifications.name")}
                </Typography>
                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="notifications-setting"
                  name="notifications"
                  className={classes.settingOption}
                  onChange={handleChangeSettingNotification}
                  value={notifications}
                >
                  <option value="enabled">
                    {i18n.t("settings.settings.notifications.options.enabled")}
                  </option>
                  <option value="disabled">
                    {i18n.t("settings.settings.notifications.options.disabled")}
                  </option>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1">
                  {i18n.t("settings.settings.enterTrigger.name")}
                </Typography>
                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="enterTriggerOnMessage-setting"
                  name="enterTriggerOnMessage"
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                  value={
                    settings &&
                    settings.length > 0 &&
                    getSettingValue("enterTriggerOnMessage")
                  }
                >
                  <option value="enabled">
                    {i18n.t("settings.settings.enterTrigger.options.enabled")}
                  </option>
                  <option value="disabled">
                    {i18n.t("settings.settings.enterTrigger.options.disabled")}
                  </option>
                </Select>
              </Grid>
              <Can
                role={user.profile}
                perform="settings:edit"
                yes={() => (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body1">
                      {i18n.t("settings.settings.groupCapture.name")}
                    </Typography>
                    <Select
                      margin="dense"
                      variant="outlined"
                      native
                      id="groupCapture-setting"
                      name="groupCapture"
                      value={
                        settings &&
                        settings.length > 0 &&
                        getSettingValue("groupCapture")
                      }
                      className={classes.settingOption}
                      onChange={handleChangeSetting}
                    >
                      <option value="enabled">
                        {i18n.t(
                          "settings.settings.groupCapture.options.enabled"
                        )}
                      </option>
                      <option value="disabled">
                        {i18n.t(
                          "settings.settings.groupCapture.options.disabled"
                        )}
                      </option>
                    </Select>
                  </Grid>
                )}
              />
              <Can
                role={user.profile}
                perform="settings:edit"
                yes={() => (
                  <Tooltip
                    title={i18n.t("settings.settings.timeAutoCloseTicket.note")}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">
                        {i18n.t("settings.settings.timeAutoCloseTicket.name")}
                      </Typography>
                      <Select
                        margin="dense"
                        variant="outlined"
                        native
                        id="timeAutoCloseTicket-setting"
                        name="timeAutoCloseTicket"
                        value={
                          settings &&
                          settings.length > 0 &&
                          getSettingValue("timeAutoCloseTicket")
                        }
                        className={classes.settingOption}
                        onChange={handleChangeSetting}
                      >
                        <option value="8">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.8"
                          )}
                        </option>
                        <option value="16">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.16"
                          )}
                        </option>
                        <option value="24">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.24"
                          )}
                        </option>
                        <option value="32">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.32"
                          )}
                        </option>
                        <option value="40">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.40"
                          )}
                        </option>
                        <option value="48">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.48"
                          )}
                        </option>
                        <option value="72">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.72"
                          )}
                        </option>
                        <option value="168">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.168"
                          )}
                        </option>
                        <option value="336">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.336"
                          )}
                        </option>
                        <option value="720">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.720"
                          )}
                        </option>
                        <option value="4380">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.4380"
                          )}
                        </option>
                        <option value="8760">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.8760"
                          )}
                        </option>
                        <option value="9999">
                          {i18n.t(
                            "settings.settings.timeAutoCloseTicket.options.9999"
                          )}
                        </option>
                      </Select>
                    </Grid>
                  </Tooltip>
                )}
              />
              <Can
                role={user.profile}
                perform="settings:edit"
                yes={() => (
                  <Tooltip
                    title={i18n.t(
                      "settings.settings.greetingMessageCount.note"
                    )}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">
                        {i18n.t("settings.settings.greetingMessageCount.name")}
                      </Typography>
                      <Select
                        margin="dense"
                        variant="outlined"
                        native
                        id="greetingMessageCount-setting"
                        name="greetingCount"
                        value={
                          settings &&
                          settings.length > 0 &&
                          getSettingValue("greetingCount")
                        }
                        className={classes.settingOption}
                        onChange={handleChangeSetting}
                      >
                        <option value="1">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.1"
                          )}
                        </option>
                        <option value="2">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.2"
                          )}
                        </option>
                        <option value="3">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.3"
                          )}
                        </option>
                        <option value="4">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.4"
                          )}
                        </option>
                        <option value="5">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.5"
                          )}
                        </option>
                        <option value="6">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.6"
                          )}
                        </option>
                        <option value="7">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.7"
                          )}
                        </option>
                        <option value="8">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.8"
                          )}
                        </option>
                        <option value="9">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.9"
                          )}
                        </option>
                        <option value="0">
                          {i18n.t(
                            "settings.settings.greetingMessageCount.options.0"
                          )}
                        </option>
                      </Select>
                    </Grid>
                  </Tooltip>
                )}
              />
              <Can
                role={user.profile}
                perform="settings:edit"
                yes={() => (
                  <Tooltip
                    title={i18n.t(
                      "settings.settings.createNewTicketAfterHours.note"
                    )}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">
                        {i18n.t(
                          "settings.settings.createNewTicketAfterHours.name"
                        )}
                      </Typography>
                      <Select
                        margin="dense"
                        variant="outlined"
                        native
                        id="createNewTicketAfterHours-setting"
                        name="createNewTicketAfterHours"
                        value={
                          settings &&
                          settings.length > 0 &&
                          getSettingValue("createNewTicketAfterHours")
                        }
                        className={classes.settingOption}
                        onChange={handleChangeSetting}
                      >
                        <option value="0">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.0"
                          )}
                        </option>
                        <option value="4">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.4"
                          )}
                        </option>
                        <option value="8">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.8"
                          )}
                        </option>
                        <option value="12">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.12"
                          )}
                        </option>
                        <option value="16">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.16"
                          )}
                        </option>
                        <option value="20">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.20"
                          )}
                        </option>
                        <option value="24">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.24"
                          )}
                        </option>
                        <option value="48">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.48"
                          )}
                        </option>
                        <option value="72">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.72"
                          )}
                        </option>
                        <option value="96">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.96"
                          )}
                        </option>
                        <option value="120">
                          {i18n.t(
                            "settings.settings.createNewTicketAfterHours.options.120"
                          )}
                        </option>
                      </Select>
                    </Grid>
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item lg container spacing={2}>
              <Grid item lg container spacing={2}>
                <Can
                  role={user.profile}
                  perform="settings:edit"
                  yes={() => (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <TextField
                        id="api-token-setting"
                        readOnly
                        label="Token API"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={
                          showToken && settings && settings.length > 0
                            ? getSettingValue("userApiToken")
                            : "••••••••"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle token visibility"
                                onClick={handleClickShowToken}
                                onMouseDown={handleMouseDownToken}
                              >
                                {showToken ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                />
                <Can
                  role={user.profile}
                  perform="settings:edit"
                  yes={() => (
                    <Grid item xs={12}>
                      <TextField
                        label="Keywords for Ignore"
                        id="keywords-ignore-setting"
                        name="keywordsIgnore"
                        value={
                          settings && settings.length > 0
                            ? getSettingValue("keywordsIgnore")
                            : ""
                        }
                        onChange={handleKeywordsIgnoreChange}
                        multiline
                        variant="outlined"
                        fullWidth
                        minRows={6}
                      />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ lineHeight: 0, fontSize: "0.6rem" }}
                      >
                        {i18n.t("settings.settings.keywordsIgnore.note")}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginTop: "10px" }}
                        fullWidth
                        onClick={handleKeywordsIgnoreClick}
                      >
                        {i18n.t("settings.settings.keywordsIgnore.button")}
                      </Button>
                    </Grid>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {/* Security Section */}
        <Can
          role={user.profile}
          perform="settings:edit"
          yes={() => (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {i18n.t("settings.settings.security.title")}
                  </Typography>
                </Grid>
                <Can
                  role={user.profile}
                  perform="settings:edit"
                  yes={() => (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">
                        {i18n.t("settings.settings.linkProtectionEnabled.name")}
                      </Typography>
                      <Select
                        margin="dense"
                        variant="outlined"
                        native
                        id="linkProtectionEnabled-setting"
                        name="linkProtectionEnabled"
                        value={
                          settings &&
                          settings.length > 0 &&
                          getSettingValue("linkProtectionEnabled")
                        }
                        className={classes.settingOption}
                        onChange={handleChangeSetting}
                      >
                        <option value="enabled">
                          {i18n.t(
                            "settings.settings.linkProtectionEnabled.options.enabled"
                          )}
                        </option>
                        <option value="disabled">
                          {i18n.t(
                            "settings.settings.linkProtectionEnabled.options.disabled"
                          )}
                        </option>
                      </Select>
                    </Grid>
                  )}
                />

                {settings &&
                  settings.length > 0 &&
                  getSettingValue("linkProtectionEnabled") === "enabled" && (
                    <>
                      <Can
                        role={user.profile}
                        perform="settings:edit"
                        yes={() => (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="body1">
                              {i18n.t(
                                "settings.settings.disableHyperlink.name"
                              )}
                            </Typography>
                            <Select
                              margin="dense"
                              variant="outlined"
                              native
                              id="disableHyperlink-setting"
                              name="disableHyperlink"
                              value={
                                settings &&
                                settings.length > 0 &&
                                getSettingValue("disableHyperlink")
                              }
                              className={classes.settingOption}
                              onChange={handleChangeSettingHyperMask}
                            >
                              <option value="enabled">
                                {i18n.t(
                                  "settings.settings.disableHyperlink.options.enabled"
                                )}
                              </option>
                              <option value="disabled">
                                {i18n.t(
                                  "settings.settings.disableHyperlink.options.disabled"
                                )}
                              </option>
                            </Select>
                          </Grid>
                        )}
                      />

                      <Can
                        role={user.profile}
                        perform="settings:edit"
                        yes={() => (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="body1">
                              {i18n.t("settings.settings.maskLink.name")}
                            </Typography>
                            <Select
                              margin="dense"
                              variant="outlined"
                              native
                              id="maskLink-setting"
                              name="maskLink"
                              value={
                                settings &&
                                settings.length > 0 &&
                                getSettingValue("maskLink")
                              }
                              className={classes.settingOption}
                              onChange={handleChangeSettingHyperMask}
                            >
                              <option value="enabled">
                                {i18n.t(
                                  "settings.settings.maskLink.options.enabled"
                                )}
                              </option>
                              <option value="disabled">
                                {i18n.t(
                                  "settings.settings.maskLink.options.disabled"
                                )}
                              </option>
                            </Select>
                          </Grid>
                        )}
                      />
                    </>
                  )}

                <Can
                  role={user.profile}
                  perform={"settings:edit"}
                  yes={() => (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography variant="body1">
                        {i18n.t(
                          "settings.settings.attachmentProtectionEnabled.name"
                        )}
                      </Typography>
                      <Select
                        margin="dense"
                        variant="outlined"
                        native
                        id="attachmentProtectionEnabled-setting"
                        name="attachmentProtectionEnabled"
                        value={
                          settings &&
                          settings.length > 0 &&
                          getSettingValue("attachmentProtectionEnabled")
                        }
                        className={classes.settingOption}
                        onChange={handleChangeSetting}
                      >
                        <option value="enabled">
                          {i18n.t(
                            "settings.settings.attachmentProtectionEnabled.options.enabled"
                          )}
                        </option>
                        <option value="disabled">
                          {i18n.t(
                            "settings.settings.attachmentProtectionEnabled.options.disabled"
                          )}
                        </option>
                      </Select>
                    </Grid>
                  )}
                />
              </Grid>
            </Paper>
          )}
        />

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Typography variant="body2">
            {i18n.t("settings.settings.version")} {getFrontEndVersion()}
          </Typography>

          <Typography variant="body2">
            {i18n.t("settings.settings.versionBackend")} {getBackEndVersion()}
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default Settings;
